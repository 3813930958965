import logo777 from "../../assets/logo777.png";
import favbet from "../../assets/favbet.png";
import supergra from "../../assets/supergra.png";
import info from "../../assets/info.png";
import stars from "../../assets/stars.png";
import prize from "../../assets/prize.png";
import hot from "../../assets/hpt.png";
import ggbet from "../../assets/ggbet.png";
import code from "../../assets/code.png";
import slotscity from "../../assets/slotscity.png";
import first from "../../assets/first.png";

export const Main = () => {
  const params = new URLSearchParams(document.location.search);
  const sub1 = params.get("sub1") ?? localStorage.getItem("sub1");
  const sub2 = params.get("sub2") ?? localStorage.getItem("sub2");
  const sub3 = params.get("sub3") ?? localStorage.getItem("sub3");
  const sub4 = params.get("sub4") ?? localStorage.getItem("sub4");
  const sub5 = params.get("sub5") ?? localStorage.getItem("sub5");
  const sub6 = params.get("sub6") ?? localStorage.getItem("sub6");
  const sub7 = params.get("sub7") ?? localStorage.getItem("sub7");
  const sub8 = params.get("sub8") ?? localStorage.getItem("sub8");
  const sub9 = params.get("sub9") ?? localStorage.getItem("sub9");

  if (sub1 !== null && sub1) {
    localStorage.setItem("sub1", sub1);
  }

  if (sub2 !== null && sub2) {
    localStorage.setItem("sub2", sub2);
  }

  if (sub3 !== null && sub3) {
    localStorage.setItem("sub3", sub3);
  }

  if (sub4 !== null && sub4) {
    localStorage.setItem("sub4", sub4);
  }

  if (sub5 !== null && sub5) {
    localStorage.setItem("sub5", sub5);
  }

  if (sub6 !== null && sub6) {
    localStorage.setItem("sub6", sub6);
  }

  if (sub7 !== null && sub7) {
    localStorage.setItem("sub7", sub7);
  }
  if (sub8 !== null && sub8) {
    localStorage.setItem("sub8", sub8);
  }
  if (sub9 !== null && sub9) {
    localStorage.setItem("sub9", sub9);
  }

  const clickHandler = (t, tgLink, offer) => {
    let e = "deep-link";
    let r = true;
    let n = {
      variables: {},
    };
    if (sub1 !== null) {
      n.variables["sub1"] = sub1;
    }
    if (sub2 !== null) {
      n.variables["sub2"] = sub2;
    }
    if (sub3 !== null) {
      n.variables["sub3"] = sub3;
    }
    if (sub4 !== null) {
      n.variables["sub4"] = sub4;
    }
    if (sub5 !== null) {
      n.variables["sub5"] = sub5;
    }
    if (sub6 !== null) {
      n.variables["sub6"] = sub6;
    }
    if (sub7 !== null) {
      n.variables["sub7"] = sub7;
    }
    if (sub8 !== null) {
      n.variables["sub8"] = sub8;
    }
    if (sub9 !== null) {
      n.variables["sub9"] = sub9;
    }
    let a = {
      context: { range: [], scope: {}, variables: n },
      messengers: [
        {
          key: "?",
          name: "telegram",
          parameter: "start",
          prefixes: ["tg://", "https://t.me/"],
        },
      ],
      delimiter: "|",
      url: "/api/i/store",
    };
    // document
    //   .getElementById("tg://resolve?start=ZGw6MTk5Mzc1&domain=sloto_ua_bot")
    //   .addEventListener("click", function (t) {
    // eslint-disable-next-line no-unused-expressions
    t.preventDefault(),
      (function (e) {
        let t = new XMLHttpRequest();
        // eslint-disable-next-line no-unused-expressions
        t.open("POST", "https://eyq13.customer.smartsender.eu/api/i/store"),
          t.setRequestHeader("Content-type", "application/json"),
          t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
          (t.onreadystatechange = function () {
            if (4 === this.readyState && 200 === this.status) {
              let t = JSON.parse(this.responseText);
              window.location.href = (function (e, t) {
                let r = a.messengers.find(function (t) {
                    return t.prefixes.find(function (t) {
                      return 0 === e.indexOf(t);
                    });
                  }),
                  n = e.split(r.key),
                  s = n[1].split("&");
                return (
                  s.forEach(function (e, n) {
                    let i = e.split("=");
                    if (r.parameter === i[0]) {
                      let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
                        /=/g,
                        ""
                      );
                      s.splice(n, 1, [r.parameter, e].join("="));
                    }
                  }),
                  n[0] + r.key + s.join("&")
                );
              })(e, t);
            }
          }),
          t.send(
            (function (e) {
              return (
                Object.keys(a.context).forEach(function (t) {
                  Object.keys(e).includes(t) || (e[t] = {});
                }),
                JSON.stringify(
                  r
                    ? ((t = e),
                      new URLSearchParams(window.location.search).forEach(
                        function (e, r) {
                          t.variables.hasOwnProperty(r) || (t.variables[r] = e);
                        }
                      ),
                      t)
                    : e
                )
              );
              var t;
            })(n)
          );
      })(tgLink);
    setTimeout(() => {
      if (offer === "super") {
        onWebsiteSuperBtnClick();
      } else if (offer === "favbet") {
        onWebsiteFavBtnClick();
      } else if (offer === "777") {
        onWebsite777BtnClick();
      } else if (offer === "ggbet") {
        onWebsiteGGbetBtnClick();
      } else if (offer === "slotscity") {
        onWebsiteSlotscityBtnClick();
      } else if (offer === "first") {
        onWebsiteFirstBtnClick();
      }
    }, 3000);
  };

  function onWebsiteFavBtnClick() {
    window.location.href =
      `https://indoorway.top/R8T7yyYQ?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSuperBtnClick() {
    window.location.href =
      `https://adsboost.top/SX9j7JvR?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsite777BtnClick() {
    window.location.href =
      `https://indoorway.top/q9K85n2G?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteGGbetBtnClick() {
    window.location.href =
      `https://adsboost.top/8wtvJLDh?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSlotscityBtnClick() {
    window.location.href =
      `https://adsboost.top/VbzbqPWw?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteFirstBtnClick() {
    window.location.href =
      `https://adsboost.top/k7v7DgBR?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  return (
    <main className="main">
      <div className="container">
        <p className="main__title">Найкращі казино</p>
        <div className="main__thumb">
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={supergra} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Super Game</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={info} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "100%" }}>
                    <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      100 ФРІСПІНІВ
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                    </p>
                  </div> */}
          {/* <div className="main__box--two">
                    <p className="main__two--textTop">БЕЗ</p>
                    <p className="main__two--textBottom">відіграшу</p>
                  </div> */}
          {/* </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjE2NTM1&domain=mega_slots_bonus_bot",
                    "super"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={slotscity} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">SC</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    {/* <p className="main__one--textTop">ФРІСПІНИ</p> */}
                    <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      100 ФРІСПІНІВ
                    </p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    {/* <p className="main__two--textTop">БЕЗ</p> */}
                    <p
                      className="main__two--textBottom"
                      style={{ fontSize: "19px" }}
                    >
                      БЕЗ ВІДІГРАШУ
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Промокод: CLUBWIN</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjE2NTMy&domain=mega_slots_bonus_bot",
                    "slotscity"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div>
          <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={first} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title main__top--titleSmaller">
                      First Casino
                    </p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}>
                    <p className="main__one--textTop">ФРІСПІНИ</p>
                    <p className="main__one--textBottom">400</p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    <p className="main__two--textTop">гривень</p>
                    <p className="main__two--textBottom">100000</p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новий бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjI0NDc5&domain=mega_slots_bonus_bot",
                    "first"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div>

          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={favbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Favbet</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--two" style={{ width: "100%" }}> */}
          {/* <p className="main__one--textTop">ФРІСПІНІВ</p> */}
          {/* <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      100 ФРІСПІНІВ
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2NjQ3&domain=free_spins_ua_bot",
                    "favbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={favbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">Favbet</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={code} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}> */}
          {/* <p className="main__one--textTop">ФРІСПІНІВ</p> */}
          {/* <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      25 ФРІСПІНІВ
                    </p>
                  </div>
                  <div className="main__box--two" style={{ width: "50%" }}>
                    <p className="main__two--textTop">промокод</p>
                    <p className="main__two--textBottom">fav25fs</p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA4NDAy&domain=free_spins_ua_bot",
                    "favbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={ggbet} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">GG</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={hot} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "50%" }}> */}
          {/* <p className="main__one--textTop">ФРІСПІНИ</p> */}
          {/* <p className="main__one--textBottom">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.43081 16.5692C7.34504 16.4835 7.277 16.3818 7.23056 16.2698C7.18412 16.1578 7.1602 16.0377 7.16016 15.9165C7.16011 15.7952 7.18395 15.6752 7.23031 15.5631C7.27667 15.4511 7.34464 15.3493 7.43034 15.2635C7.60343 15.0903 7.83823 14.993 8.08309 14.9929C8.20434 14.9928 8.3244 15.0167 8.43644 15.063C8.54847 15.1094 8.65027 15.1774 8.73604 15.2631C9.38146 15.9086 10.2038 16.3483 11.0992 16.5265C11.9945 16.7047 12.9225 16.6134 13.766 16.2641C14.6094 15.9148 15.3303 15.3233 15.8375 14.5643C16.3447 13.8052 16.6154 12.9129 16.6154 12C16.6154 11.7552 16.7127 11.5204 16.8858 11.3473C17.0589 11.1742 17.2937 11.0769 17.5385 11.0769C17.7833 11.0769 18.0181 11.1742 18.1912 11.3473C18.3643 11.5204 18.4616 11.7552 18.4616 12C18.4617 13.278 18.0828 14.5274 17.3728 15.5901C16.6628 16.6527 15.6536 17.481 14.4728 17.9701C13.2921 18.4592 11.9928 18.5872 10.7393 18.3378C9.48585 18.0884 8.33447 17.473 7.43081 16.5692Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M15.2871 14.6141C15.1862 14.6814 15.0731 14.7282 14.9542 14.7517C14.8352 14.7753 14.7128 14.7752 14.594 14.7514C14.4751 14.7277 14.362 14.6808 14.2613 14.6133C14.1605 14.5459 14.074 14.4593 14.0068 14.3584C13.9395 14.2576 13.8927 14.1445 13.8692 14.0255C13.8456 13.9066 13.8457 13.7842 13.8694 13.6653C13.8932 13.5464 13.9401 13.4334 14.0075 13.3326C14.075 13.2319 14.1616 13.1454 14.2624 13.0781L17.0317 11.232C17.2354 11.0961 17.4847 11.0467 17.7248 11.0947C17.9649 11.1426 18.1761 11.284 18.312 11.4877C18.4479 11.6914 18.4973 11.9407 18.4493 12.1808C18.4013 12.4209 18.26 12.6321 18.0563 12.768L15.2871 14.6141Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M20.1531 14.2569C20.2204 14.3577 20.2672 14.4708 20.2907 14.5898C20.3143 14.7087 20.3142 14.8311 20.2904 14.95C20.2667 15.0689 20.2198 15.1819 20.1523 15.2827C20.0849 15.3834 19.9983 15.4699 19.8974 15.5372C19.7966 15.6045 19.6835 15.6512 19.5645 15.6748C19.4456 15.6983 19.3232 15.6982 19.2043 15.6745C19.0854 15.6507 18.9724 15.6038 18.8716 15.5364C18.7709 15.469 18.6844 15.3823 18.6171 15.2815L16.771 12.5123C16.7037 12.4114 16.6569 12.2983 16.6334 12.1794C16.6098 12.0604 16.6099 11.938 16.6337 11.8191C16.6816 11.579 16.823 11.3678 17.0267 11.2319C17.2303 11.0961 17.4797 11.0467 17.7198 11.0946C17.8387 11.1184 17.9517 11.1653 18.0525 11.2327C18.1532 11.3002 18.2397 11.3868 18.307 11.4876L20.1531 14.2569ZM16.4839 7.24702C16.5721 7.33218 16.6424 7.43403 16.6908 7.54665C16.7391 7.65927 16.7646 7.7804 16.7657 7.90296C16.7667 8.02553 16.7434 8.14708 16.697 8.26052C16.6505 8.37396 16.582 8.47703 16.4953 8.5637C16.4087 8.65037 16.3056 8.71891 16.1922 8.76532C16.0787 8.81174 15.9572 8.83509 15.8346 8.83403C15.712 8.83296 15.5909 8.8075 15.4783 8.75912C15.3657 8.71074 15.2638 8.64042 15.1787 8.55226C14.5332 7.90661 13.7107 7.4669 12.8153 7.28874C11.9199 7.11058 10.9918 7.20199 10.1483 7.55138C9.30486 7.90078 8.58397 8.49248 8.07684 9.25164C7.5697 10.0108 7.29911 10.9033 7.29928 11.8163C7.29928 12.0611 7.20203 12.2959 7.02892 12.469C6.85581 12.6421 6.62102 12.7393 6.3762 12.7393C6.13139 12.7393 5.8966 12.6421 5.72349 12.469C5.55038 12.2959 5.45313 12.0611 5.45313 11.8163C5.45303 10.5382 5.83195 9.28885 6.54194 8.22617C7.25194 7.16349 8.26113 6.33523 9.44188 5.84613C10.6226 5.35704 11.9219 5.22907 13.1754 5.47843C14.4289 5.72779 15.5802 6.34327 16.4839 7.24702Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M8.63026 9.20219C8.83395 9.06632 9.08327 9.01693 9.32337 9.06488C9.56347 9.11283 9.7747 9.2542 9.91057 9.45789C10.0464 9.66157 10.0958 9.91089 10.0479 10.151C9.99993 10.3911 9.85856 10.6023 9.65488 10.7382L6.88472 12.5843C6.78387 12.6516 6.67075 12.6984 6.55182 12.7219C6.4329 12.7455 6.3105 12.7454 6.19161 12.7217C6.07272 12.6979 5.95968 12.651 5.85893 12.5836C5.75817 12.5161 5.67169 12.4295 5.60441 12.3287C5.46854 12.125 5.41915 11.8756 5.4671 11.6355C5.51505 11.3954 5.65642 11.1842 5.86011 11.0483L8.63026 9.20219Z"
                          fill="#4AFFAE"
                        />
                        <path
                          d="M3.76066 9.5594C3.69338 9.45855 3.64663 9.34543 3.62307 9.2265C3.59951 9.10758 3.5996 8.98518 3.62335 8.86629C3.64709 8.7474 3.69402 8.63436 3.76145 8.53361C3.82888 8.43285 3.9155 8.34637 4.01635 8.27909C4.11721 8.21182 4.23033 8.16506 4.34925 8.1415C4.46817 8.11794 4.59057 8.11804 4.70946 8.14178C4.82835 8.16552 4.9414 8.21245 5.04215 8.27988C5.1429 8.34731 5.22938 8.43393 5.29666 8.53479L7.14281 11.304C7.27869 11.5077 7.32808 11.757 7.28013 11.9971C7.23218 12.2372 7.09081 12.4485 6.88712 12.5843C6.68344 12.7202 6.43412 12.7696 6.19401 12.7216C5.95391 12.6737 5.74269 12.5323 5.60681 12.3286L3.76066 9.5594Z"
                          fill="#4AFFAE"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22.1538C17.6077 22.1538 22.1538 17.6077 22.1538 12C22.1538 6.39231 17.6077 1.84615 12 1.84615C6.39231 1.84615 1.84615 6.39231 1.84615 12C1.84615 17.6077 6.39231 22.1538 12 22.1538ZM12 24C18.6277 24 24 18.6277 24 12C24 5.37231 18.6277 0 12 0C5.37231 0 0 5.37231 0 12C0 18.6277 5.37231 24 12 24Z"
                          fill="#4AFFAE"
                        />
                      </svg>
                      50 ФРІСПІНІВ
                    </p>
                  </div>
                  <div
                    className="main__box--two"
                    style={{
                      width: "50%",
                    }}
                  > */}
          {/* <p className="main__two--textTop">БЕЗ</p> */}
          {/* <p
                      className="main__two--textBottom"
                      style={{
                        fontSize: "19px",
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.2782 20.2782C18.2153 22.3411 15.4174 23.5 12.5 23.5C9.58262 23.5 6.78473 22.3411 4.72183 20.2782C2.65893 18.2153 1.5 15.4174 1.5 12.5C1.5 9.58262 2.65893 6.78473 4.72183 4.72183C6.78473 2.65893 9.58262 1.5 12.5 1.5C15.4174 1.5 18.2153 2.65893 20.2782 4.72182C22.3411 6.78473 23.5 9.58262 23.5 12.5C23.5 15.4174 22.3411 18.2153 20.2782 20.2782Z"
                          stroke="#4AFFAE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.71094 15.5732C8.79153 16.5028 9.23366 17.3637 9.94229 17.9708C10.6509 18.5778 11.5694 18.8826 12.5004 18.8195C15.5573 18.8195 16.2899 17.0637 16.2899 15.5732C16.2899 14.0827 15.0267 12.5037 12.5004 12.5037C9.9741 12.5037 8.71094 11.5058 8.71094 9.47217C8.73449 9.00431 8.85244 8.54605 9.05768 8.12496C9.26291 7.70386 9.5512 7.32863 9.9052 7.02183C10.2592 6.71502 10.6716 6.483 11.1176 6.33969C11.5636 6.19639 12.034 6.14478 12.5004 6.18795C13.432 6.14784 14.3436 6.46574 15.0483 7.07644C15.7529 7.68714 16.1972 8.54433 16.2899 9.47217M12.5004 20.7105V19.0684M12.5004 4.28943V6.1829"
                          stroke="#4AFFAE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      12,000 грн
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">Новий бонус</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjE2NTI5&domain=mega_slots_bonus_bot",
                    "ggbet"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div className="main__item">
              <div className="main__wrapper--top">
                <div className="main__wrapper--two">
                  <img src={logo777} alt="supergra" className="main__logo" />
                  <div>
                    <p className="main__top--title">777</p>
                    <img src={stars} alt="" className="main__stars" />
                  </div>
                </div>
                <img src={info} alt="info" className="main__info--svg" />
              </div>
              <div className="main__bonus">
                <div className="main__box--wrapper">
                  <div className="main__box--one" style={{ width: "100%" }}>
                    <p className="main__one--textTop">БОНУС</p>
                    <p className="main__one--textBottom">200 грн</p>
                  </div>
                   <div className="main__box--two">
                    <p className="main__two--textTop">БОНУС</p>
                    <p className="main__two--textBottom">
                      10<span style={{ letterSpacing: "0.1em" }}>0</span>00
                      <span style={{ letterSpacing: "0.1em" }}>0</span>₴
                    </p>
                  </div>
                </div>
                <div className="main__box--three">
                  <p className="main__three--text">БЕЗ ВІДІГРАШУ</p>
                </div>
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2NjUz&domain=free_spins_ua_bot",
                    "777"
                  );
                }}
              >
                <p className="main__button--text">Забрати бонус</p>
                <img src={prize} alt="prize" width={23} />
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </main>
  );
};
