import logo from "../../assets/logo.png";

export const Header = () => {
  return (
    <header className="header">
      {/* <div className="container"> */}
      <img src={logo} alt="logotype" className="header__logo" />
      {/* </div> */}
    </header>
  );
};
